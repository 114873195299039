<template>
    <section class="gray-banner section is-marginless is-paddingless">
        <div class="container">
            <div class="columns is-marginless">
                <div class="gray-banner__content column has-text-centered" v-html="data.content"></div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: "GrayBanner",
    data() {
        return {}
    }
}
</script>

<style scoped lang="scss">
@import "@/styles/framework/variables.sass";

.gray-banner {
    background-color: $bg;
    &__content {
        padding: 3rem 0;
        @include touch {
            padding: 2rem 1rem;
        }
        /deep/ & h2 {
            color: $pink;
            font-size: 1.5rem;
            margin-bottom: .75rem;
            font-weight: bold;
            font-family: 'Gilroy bold';
        }
        /deep/ & p {
            color: $dark-blue;
            font-size: 1.2rem;
        }
    }
}
</style>