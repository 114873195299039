<template>
    <section class="small-blue-tiles section is-margnless is-paddingless">
        <div class="small-blue-tiles__content container">
            <div class="columns">
                <div class="column has-text-centered">
                    <h2>{{ data.title }}</h2>
                </div>
            </div>
            <div class="columns">
                <div class="small-blue-tiles__single-tile column"
                    v-for="(tile, index) in data.tiles"
                    :key="index" :style="{ 'background-color': tile.color }">
                    <div class="has-text-centered" v-html="tile.content"></div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: "SmallblueTiles",
    data() {
        return {}
    }
}
</script>

<style scoped lang="scss">
@import "@/styles/framework/variables.sass";

.small-blue-tiles {
    &__content {
        padding: 3rem 0;
        @include touch {
            padding: 2rem 0;
        }
        & h2 {
            font-size: 1.5rem;
            font-weight: bold;
            color: $dark-blue;
            margin-bottom: 1rem;
            font-family: 'Gilroy bold';
        }
    }
    &__single-tile {
        background-color: $dark-blue;
        color: $white;
        padding: 2.5rem 1.5rem;
    }
}
</style>