<template>
  <header class="header">
    <div class="header__contanier container">
      <div class="header__columns columns">
        <div class="header__logo-box column">
          <a href="https://ssw.solutions/en/" target="_blank">
            <img class="header__logo" src="@/assets/SSW_LOGO.png" alt="SSW logo" />
          </a>
        </div>
        <div class="header__social-box column">
            <div class="header__lang-switcher" v-if="languages.length > 1">
                <a
                  class="header__navbar-link header__navbar-link--current"
                  v-for="(lang, index) in languages"
                  @click="menuLang = !menuLang"
                  :href="langHomeUrl(lang.home_url)"
                  v-if="lang.lang == locale"
                  :key="index"
                >{{ lang.name }}</a>
                <div class="header__navbar-dropdown">
                  <a
                    class="header__navbar-link header__navbar-link--hidden"
                    v-for="(lang, index) in languages"
                    :href="langHomeUrl(lang.home_url)"
                    v-if="lang.lang != locale"
                    :key="index"
                  >{{ lang.name }}</a>
                </div>
          </div>
          <a
            class="header__icon-box"
            href="https://www.linkedin.com/company/1356327/"
            target="_blank"
          >
            <i aria-hidden="true" class="fa fa-linkedin-square"></i>
          </a>
          <a
            class="header__icon-box"
            href="https://www.facebook.com/SSW-Pragmatic-Solutions-198477040912548/"
            target="_blank"
          >
            <i aria-hidden="true" class="fa fa-facebook-square"></i>
          </a>
          <a class="header__icon-box" href="https://twitter.com/PragmaticSsw" target="_blank">
            <i aria-hidden="true" class="fa fa-twitter-square"></i>
          </a>
          <a
            class="header__icon-box"
            href="https://www.youtube.com/channel/UCJSyuGORhen_7VBZ2pC7h5Q"
            target="_blank"
          >
            <i aria-hidden="true" class="fa fa-youtube-play"></i>
          </a>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
export default {
  data() {
    return {
      burger: false,
      menuLang: false,
      activeSubmenu: 0
    };
  },
  created() {},
  methods: {
    langHomeUrl(home_url) {
      return this.publicPath + home_url;
    },
    navigateTo(url) {
      const publicPath = "/" + this.$store.getters.getPublicPath;
      const locale = this.$store.getters.getLocale;
      if (url != "#") {
        this.$router.push(url);
        this.burger = false;
      }
    }
  },
  computed: {
    languages() {
      return this.$store.getters.getLanguages;
    },
    options() {
      return this.$store.getters.getOptions;
    },
    locale() {
      return this.$store.getters.getLocale;
    },
    menuLocale() {
      return this.locale != this.$store.getters.getOptions.default_language
        ? this.locale + "/"
        : "";
    },
    publicPath() {
      return this.$store.getters.getPublicPath;
    },
    mainMenu() {
      return this.$store.getters.getMainMenu;
    }
  }
};
</script>

<style scoped lang="scss">
@import "@/styles/framework/variables.sass";

.header {
  padding: 20px 0;
  width: 100%;
  display: flex;
  box-shadow: 0px 3px 3px 0px rgba(204, 204, 204, 1);
  @include touch {
    padding: 40px 20px;
  }
  &__contanier {
    width: 100%;
  }
  &__columns {
    display: flex;
    justify-content: space-between !important;
    width: 100%;
  }
  &__logo-box {
    border-right: 1px solid #dcdcdc;
    max-width: 120px;
    padding-right: 15px;
    & a {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
    }
  }
  &__lang-switcher
  &__navbar-end {
    justify-content: flex-end;
    margin-left: auto;
    align-items: stretch;
    display: flex;
  }
  &__lang-switcher {
    align-items: stretch;
    padding: 0;
    display: flex;
    flex-grow: 0;
    flex-shrink: 0;
    color: #4a4a4a;
    display: block;
    line-height: 1.5;
    padding: 0.5rem 0.75rem;
    position: relative;
    transition: background-color .2s ease-in-out;
    cursor: pointer;
    &:hover {
        background-color: darken(white, 10%);
    }
    &:hover .header__navbar-dropdown {
        display: block;
    }
    @include touch {
        padding: 5px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
    }
  }
  &__navbar-dropdown {
    background-color: #fff;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    border-top: 2px solid #dbdbdb;
    box-shadow: 0 8px 8px rgba(0,0,0,.1);
    display: none;
    font-size: .875rem;
    left: 0;
    min-width: 100%;
    position: absolute;
    top: 100%;
    z-index: 20;
    @include touch {
        display: block;
        border: none;
        position: inherit;
        box-shadow: none;
    }
    & .header__lang-switcher {
        padding-right: 3rem;
        padding: .375rem 1rem;
        white-space: nowrap;
    }
  }
  &__navbar-link {
    align-items: center;
    display: flex;
    color: black;
    @include touch {
        padding: .05rem;
    }
    &--current {
        padding-right: 2.5em;
        align-items: center;
        @include touch {
            font-weight: bold;
            color: #3273dc;
        }
        &::after {
            margin-top: -.375em;
            right: 1.125em;
            border: 3px solid #3273dc;
            border-radius: 2px;
            border-right: 0;
            border-top: 0;
            content: " ";
            display: block;
            height: .625em;
            margin-top: -.4375em;
            pointer-events: none;
            position: absolute;
            top: 50%;
            -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
            -webkit-transform-origin: center;
            transform-origin: center;
            width: .625em;
        }
    }
    &--hidden {
        transition:  background-color .2s ease-in-out;
        padding: 1rem;
        @include touch {
            padding: .05rem;
        }
        &:hover {
            background-color: darken(white, 10%)
        }
    }
  }
  &__social-box {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-right: 0;
    padding-right: 0;
  }
  &__icon-box {
    margin: 10px;
    color: $dark-blue;
    @include mobile {
        margin: 2px;
    }
    &:last-child {
      margin-right: 0;
    }
    & i {
      color: $dark-blue;
      font-size: 30px;
      @include touch {
          font-size: 26px;
      }
    }
  }
}
</style>
