<template>
    <section class="contact section is-paddingless is-marginless">
        <div class="contact__content container">
            <div class="columns is-marginless">
                <div class="column is-6 is-paddingless">
                    <div class="columns is-marginless">
                        <h2 class="contact__heading contact__heading--pink">{{ data.contact_us.title }}</h2>
                    </div>
                    <div class="contact__profiles-container">
                        <div class="contact__profile" v-for="(employee, index) in data.contact_us.employees" :key="index">
                            <div class="contact__1-of-2-profile">
                                <a :href="employee.link">
                                    <figure class="image">
                                        <img class="contact__person-image" :src="employee.image.url" :alt="employee.image.alt">
                                    </figure>
                                </a>
                            </div>
                            <div class="contact__1-of-2-profile contact__profile-text-box">
                                <h3 class="contact__name">{{ employee.name }}</h3>
                                <span class="contact__position">{{ employee.position }}</span>
                                <a class="contact__icon" :href="'mailto:' + employee.mail"><i aria-hidden="true" class="fa fa-envelope"></i></a>
                                <a class="contact__more" :href="employee.link">{{ employee.more_text }}</a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="column is-6 is-paddingless">
                    <div class="columns is-marginless">
                        <h2 class="contact__heading contact__heading--blue">{{ data.related_services.title }}</h2>
                    </div>
                    <div class="contact__services-container">
                        <div class="contact__related-service" v-for="(service, index) in data.related_services.services" :key="index">
                            <a :href="service.link">
                                <img :src="service.image.url" :alt="service.image.alt">
                            </a>
                            <a class="contact__related-service-name" :href="service.link"><h3>{{ service.name }}</h3></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: "ContactSection",
    data() {
        return {}
    }
}
</script>

<style scoped lang="scss">
@import "@/styles/framework/variables.sass";

.contact {
    &__content {
        padding: 0 1rem 2rem;
        @include mobile {
            padding: 2rem 1rem;
        }
    }
    &__heading {
        font-size: 1.5rem;
        font-weight: bold;
        margin-bottom: 1.5rem;
        font-family: 'Gilroy bold';
        &--pink {
            color: $pink;
        }
        &--blue {
            color: $dark-blue;
        }
    }
    &__profile {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        box-sizing: border-box;
        margin-bottom: 1rem;
    }
    &__profile-text-box {
        margin-left: 15px;
        display: flex;
        flex-direction: column;
        width: 50%;
        justify-content: space-between;
        height: 100%;
    }
    &__profiles-container {
        display: flex;
        flex-wrap: wrap;
        @include touch {
            flex-wrap: none;
            flex-direction: column;
        }
    }
    &__person-image {
        height: 150px;
        width: 150px;
    }
    &__name,
    &__position,
    &__icon,
    &__more {
        margin-bottom: .5rem;
        display: block;
    }
    &__name {
        font-weight: bold;
        color: $dark-blue;
        font-size: 1.2rem;
        max-width: 100px;
        font-family: 'Gilroy bold';
    }
    &__linkedin {
        font-size: 1.2rem;
    }
    &__position {
        font-size: .95rem;
        color: $pink;
    }
    &__icon {
        font-size: 1.2rem;
        transition: color .2s ease-in-out;
    }
    &__more {
        text-transform: uppercase;
        color: #959595;
        display: flex;
        height: 1.25rem;
        cursor: pointer;
        font-size: 1rem;
        &::after {
            content: "";
            line-height: 1;
            display: inline-block;
            background-repeat: no-repeat;
            background-position: 50%;
            background-size: contain;
            width: 1.5rem;
            height: 1.25rem;
            vertical-align: middle;
            margin-left: 10px;
            transition: all .35s ease-in-out;
        }
    }
    &__services-container {
        display: flex;
        flex-wrap: wrap;
        @include mobile {
            flex-wrap: nowrap;
            flex-direction: column;
        }
    }
    &__related-service {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        box-sizing: border-box;
        margin-bottom: 2rem;
        flex-direction: column;
        margin-right: 2rem;
        @include mobile {
            justify-content: flex-start;
            flex-direction: row;
        }
        & img {
            width: 150px;
            height: 150px;
            flex-grow: unset;
        }
        & h3 {
            color: $dark-blue;
            font-weight: bold;
            font-size: 1.2rem;
            font-family: 'Gilroy bold';
        }
    }
    &__related-service-name {
        @include touch {
            margin-left: 1rem;
        }
    }
}
</style>