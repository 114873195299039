<template>
    <section class="text-and-image section is-marginless is-paddingless">
        <div class="container">
            <div class="columns is-marginless">
                <div class="text-and-image__text-box column is-6" v-html="data.content"></div>
                <div class="text-and-image__image-box column is-6" :style="{ 'background-image': 'url(' + data.image + ')' }"></div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name:"TextAndImage",
    data() {
        return {}
    }
}
</script>

<style scoped lang="scss">
@import "@/styles/framework/variables.sass";

.text-and-image {
    &__text-box {
        padding: 3rem 1rem;
        /deep/ & p {
            font-size: 1.1rem;
            color: $dark-blue;
            &:not(:last-child) {
                margin-bottom: .75rem;
            }
        }
    }
    &__image-box {
        background-size: cover;
        @include mobile {
            min-height: 300px;
        }
    }
}
</style>
