<template>
    <section class="two-tiles section is-marginless is-paddingless">
        <div class="two-tiles__content container">
            <div class="columns is-marginless">
                <div class="two-tiles__single-tile column is-6" v-html="data.first_contnet" :style="{ 'background-image': 'url(' + data.image_background + ')' }"></div>
                <div class="two-tiles__single-tile column is-6" v-html="data.second_content"></div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: "TwoTiles",
    data() {
        return {}
    }
}
</script>

<style scoped lang="scss">
@import "@/styles/framework/variables.sass";

.two-tiles {
    background-color: #4069ba;
    color: $white;
    &__single-tile {
        padding: 3rem 2rem;
        background-repeat: no-repeat;
        background-size: cover;
        @include touch {
            padding: 2rem 1rem;
        }
        /deep/ & li {
            margin-bottom: 1rem;
            display: flex;
            &::before {
                content: "\2192";
                color: #fff;
                font-family: Gilroy,sans-serif;
                font-size: 17px;
                line-height: 1.5;
                display: block;
                margin-right: 5px;
            }
        }
    }
}
</style>