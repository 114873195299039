<template>
  <section class="contact-form section">
    <div class="container">
      <div class="columns is-centered">
        <div class="column is-8 has-text-centered">
          <h2 class="contact-form__title" v-html="data.title"></h2>
          <form @submit.prevent="onSubmit">
            <p class="contact-form__info-text">
              The Controller of your personal data is Spaczyński, Szczepaniak i Wspólnicy sp.k., ul. Rondo ONZ 1, p. 12, 00-124 Warsaw. Your personal data contained in your form are processed in order to enable us to contact you. The details concerning our processing of your personal data, including your rights connected with your personal data, can be found in our privacy policy available here
              <a
                href="https://ssw.solutions/en/privacy-policy/"
                target="_blank"
              >Privacy Policy</a>
            </p>
            <div class="columns">
              <div class="contact-form__inputs-box column">
                <div class="field">
                  <div class="control">
                    <input
                      class="input"
                      type="text"
                      name="name"
                      v-model="name"
                      required
                      placeholder="Name"
                    />
                  </div>
                </div>
                <div class="field">
                  <div class="control">
                    <input
                      class="input"
                      type="text"
                      name="email"
                      v-model="email"
                      required
                      placeholder="Company"
                    />
                  </div>
                </div>
                <div class="field">
                  <div class="control">
                    <input
                      class="input"
                      type="text"
                      name="company"
                      v-model="company"
                      required
                      placeholder="E-mail"
                    />
                  </div>
                </div>
              </div>
              <div class="column">
                <div class="field">
                  <div class="control">
                    <textarea
                      class="textarea"
                      name="message"
                      rows="8"
                      cols="80"
                      v-model="message"
                      placeholder="Message"
                    ></textarea>
                  </div>
                </div>
              </div>
            </div>
            <div class="field">
              <div class="control">
                <label class="checkbox">
                  <input type="checkbox" name="agreement-1" id="agreement-1" required />
                  <span class="contact-form__term">
                    I agree to the processing of my personal data contained in the form by
                    <b>Spaczyński, Szczepaniak i Wspólnicy sp.k.</b> with its registered office in Warsaw for marketing and contact purposes.
                  </span>
                </label>
                <p class="contact-form__paragraph-center">
                  If you are interested in obtaining offers and materials concerning the products, services and events of
                  <b>Spaczyński, Szczepaniak i Wspólnicy sp.k.</b>, please indicate your agreement below.
                </p>
                <label class="checkbox">
                  <input type="checkbox" name="agreement-1" id="agreement-1" required />
                  <span class="contact-form__term">
                    I agree to receive information about the products, services and events of
                    <b>Spaczyński, Szczepaniak i Wspólnicy sp.k.</b> with its registered office in Warsaw.
                  </span>
                </label>
              </div>
            </div>
            <vue-recaptcha
              ref="recaptcha"
              @verify="onVerify"
              @expired="onExpired"
              size="invisible"
              :sitekey="sitekey"
            ></vue-recaptcha>
            <div class="field">
              <div class="control">
                <input
                  class="button contact-form__send"
                  type="submit"
                  name="submit"
                  :value="sendLabel"
                />
              </div>
            </div>
            <transition name="fade" mode="out-in">
              <article
                class="message"
                :class="{'is-success':infoBox.type == 1,'is-danger':infoBox.type == 2}"
                v-if="infoBox.type != 0"
                @click="infoBox.type = 0"
              >
                <div class="message-header">
                  <p>{{ infoBox.type == 1 ? 'Sucess' : 'Error' }}</p>
                  <button class="delete" aria-label="delete"></button>
                </div>
                <div class="message-body">{{ infoBox.content }}</div>
              </article>
            </transition>
          </form>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import VueRecaptcha from "vue-recaptcha";
import axios from "axios";
export default {
  components: {
    "vue-recaptcha": VueRecaptcha
  },
  data() {
    return {
      name: "",
      email: "",
      message: "",
      company: "",
      sendLabel: "Senden",
      infoBox: {
        type: 0, // 0 - no, 1 - success, 2 - error
        content: ""
      },
      sitekey: process.env.VUE_APP_RECAPTCHA_SITE_KEY
    };
  },
  props: {
    data: Object,
    printClass: Function,
    className: String
  },
  methods: {
    onSubmit() {
      this.$refs.recaptcha.execute();
      this.sendLabel = "Sending...";
    },
    onVerify(recaptchaToken) {
      this.$refs.recaptcha.reset();
      var bodyFormData = new FormData();
      bodyFormData.set("name", this.name);
      bodyFormData.set("email", this.email);
      bodyFormData.set("message", this.message);
      bodyFormData.set("company", this.company);
      bodyFormData.set("recaptchaToken", recaptchaToken);

      const config = {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      };
      axios
        .post(
          "https://api.betterandmore.eu/bedrock/wp-json/better-api/v1/germandesk",
          bodyFormData,
          config
        )
        .then(response => {
          if (response.data == 0) {
            this.infoBox.type = 1;
            this.infoBox.content = "Email was send successfully.";
            this.sendLabel = "Send";
            this.name = "";
            this.company = "";
            this.message = "";
            this.email = "";
            setTimeout(() => {
              this.infoBox.type = 0;
            }, 3000);
          } else {
            this.infoBox.type = 2;
            this.infoBox.content = "Server side error";
            this.sendLabel = "Send";
            setTimeout(() => {
              this.infoBox.type = 0;
            }, 3000);
          }
        })
        .catch(err => {
          this.infoBox.type = 2;
          this.infoBox.content = "Cannot send email, please try again later.";
          this.sendLabel = "Send";
          setTimeout(() => {
            this.infoBox.type = 0;
          }, 3000);
          console.log(getErrorMessage(err));
          //helper to get a displayable message to the user
          function getErrorMessage(err) {
            let responseBody;
            responseBody = err.response;
            if (!responseBody) {
              responseBody = err;
            } else {
              responseBody = err.response.data || responseBody;
            }
            return responseBody.message || JSON.stringify(responseBody);
          }
        });
    },
    onExpired() {
      console.log("Expired");
    }
  }
};
</script>

<style scoped lang="scss">
@import "@/styles/framework/variables.sass";

.contact-form {
  background-color: $bg;
  padding: 2rem 1rem !important;
  &__title {
    color: $dark-blue;
    font-size: 1.7rem;
    font-family: "Gilroy bold";
    margin-bottom: 1rem;
  }
  &__info-text {
    font-size: 0.85rem;
    color: #959595;
    margin-bottom: 1.5rem;
    text-align: left;
  }
  &__inputs-box {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  &__paragraph-center {
    margin-bottom: 1rem;
    text-align: left;
    color: $dark-blue;
  }
  & .checkbox {
    margin-bottom: 1rem;
    & input[type="checkbox"] {
      margin-right: 0.5rem;
    }
  }
  &__term {
    color: $dark-blue;
  }
  &__send {
    background-color: $dark-blue;
    border-color: transparent;
    color: $white;
    &:hover {
      color: $white;
      border-color: transparent;
      background-color: lighten($dark-blue, 10%);
    }
    &:focus {
      background-color: $dark-blue;
      color: $white;
    }
  }
}
</style>