<template>
    <section class="intro section is-paddingless is-marginless">
        <div class="intro__contianer container">
            <div class="columns is-marginless">
                <div class="intro__video-box column is-6">
                    <video playsinline="" loop="loop" preload="auto" autoplay="autoplay" muted="muted" class="intro__video">
                        <source :src="data.video" type="video/mp4">
                    </video>
                </div>
                <div class="intro__text-box column is-6" v-html="data.content"></div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: "Intro",
    data() {
        return {}
    }
}
</script>

<style scoped lang="scss">
@import "@/styles/framework/variables.sass";

.intro {
    color: $white;
    &__contianer {
        background-color: $intro-blue;
        border-bottom: 8px solid $pink;
    }
    &__video-box {
        display: flex;
        justify-content: center;
        align-items: center;
        & video {
            height: 300px;
            @include mobile {
                height: 250px;
            }
        }
    }
    &__text-box {
        padding: 4rem 2rem 4rem;
        @include mobile {
            padding: 2rem 1rem;
        }
        /deep/ & h1 {
            margin-bottom: .75rem;
            display: block;
            font-size: 1.5rem;
            color: $pink;
            font-weight: bold;
            font-family: 'Gilroy bold';
        }
        /deep/ & p {
            font-size: 1.1rem;
        }
    }
}
</style>

