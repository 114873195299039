<template>
  <section class="contact-form section">
    <div class="container">
      <div class="columns is-centered">
        <div class="column is-8 has-text-centered">
          <h2 class="contact-form__title" v-html="data.title"></h2>
          <form @submit.prevent="onSubmit">
            <p class="contact-form__info-text">
              Der Verantwortliche für die Verarbeitung Ihrer personenbezogenen Daten ist Spaczyński Szczepaniak i Wspólnicy sp.k. mit Sitz in der Straße: Rondo ONZ 1, 12. Stock, 00-124 Warszawa. Ihre Sie in dem Kontaktformular übermittelten personenbezogenen Daten, werden von uns zum Zweck der Kontaktaufnahme mit Ihnen verarbeitet. Die Einzelheiten darüber, wie wir Ihre personenbezogenen Daten verarbeiten, darunter über die Ihnen in Bezug auf Ihre personenbezogenen Daten zustehenden Rechte, finden Sie in der unter dem Link „Datenschutzerklärung“
              <a
                href="https://ssw.solutions/en/privacy-policy-de/"
                target="_blank"
              >abrufbar Datenschutzerklärung.</a>
            </p>
            <div class="columns">
              <div class="contact-form__inputs-box column">
                <div class="field">
                  <div class="control">
                    <input
                      class="input"
                      type="text"
                      name="name"
                      v-model="name"
                      required
                      placeholder="Name"
                    />
                  </div>
                </div>
                <div class="field">
                  <div class="control">
                    <input
                      class="input"
                      type="text"
                      name="email"
                      v-model="email"
                      required
                      placeholder="Firma"
                    />
                  </div>
                </div>
                <div class="field">
                  <div class="control">
                    <input
                      class="input"
                      type="text"
                      name="company"
                      v-model="company"
                      required
                      placeholder="E-mail"
                    />
                  </div>
                </div>
              </div>
              <div class="column">
                <div class="field">
                  <div class="control">
                    <textarea
                      class="textarea"
                      name="message"
                      rows="8"
                      cols="80"
                      v-model="message"
                      placeholder="Nachricht"
                    ></textarea>
                  </div>
                </div>
              </div>
            </div>
            <div class="field">
              <div class="control">
                <label class="checkbox">
                  <input type="checkbox" name="agreement-1" id="agreement-1" required />
                  <span class="contact-form__term">
                    Hiermit willige ich ein, dass meine in dem Formular enthaltene Daten von
                    <b>Spaczyński Szczepaniak i Wspólnicy sp.k.</b> mit Sitz in Warschau zu Kontakt- und Marketingzwecken verarbeitet werden.
                  </span>
                </label>
                <p class="contact-form__paragraph-center">
                  Sind Sie am Erhalt der Angebote und Unterlagen über Produkte und Dienstleistungen der Kanzlei
                  <b>Spaczyński Szczepaniak i Wspólnicy sp.k.</b> interessiert, dann bitten wir Sie um Erteilung Ihrer Einwilligung zur Übersendung solcher Unterlagen:
                </p>
                <label class="checkbox">
                  <input type="checkbox" name="agreement-1" id="agreement-1" required />
                  <span
                    class="contact-form__term"
                  >Ich willige ein, dass mir von der Kanzlei Spaczyński Szczepaniak i Wspólnicy sp.k. mit Sitz in Warschau Informationen über Produkte, Dienstleistungen und Events von Spaczyński Szczepaniak i Wspólnicy sp.k. zugesandt werden.</span>
                </label>
              </div>
            </div>
            <vue-recaptcha
              ref="recaptcha"
              @verify="onVerify"
              @expired="onExpired"
              size="invisible"
              :sitekey="sitekey"
            ></vue-recaptcha>
            <div class="field">
              <div class="control">
                <input
                  class="button contact-form__send"
                  type="submit"
                  name="submit"
                  :value="sendLabel"
                />
              </div>
            </div>
            <transition name="fade" mode="out-in">
              <article
                class="message"
                :class="{'is-success':infoBox.type == 1,'is-danger':infoBox.type == 2}"
                v-if="infoBox.type != 0"
                @click="infoBox.type = 0"
              >
                <div class="message-header">
                  <p>{{ infoBox.type == 1 ? 'Sucess' : 'Error' }}</p>
                  <button class="delete" aria-label="delete"></button>
                </div>
                <div class="message-body">{{ infoBox.content }}</div>
              </article>
            </transition>
          </form>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import VueRecaptcha from "vue-recaptcha";
import axios from "axios";
export default {
  components: {
    "vue-recaptcha": VueRecaptcha
  },
  data() {
    return {
      name: "",
      email: "",
      message: "",
      company: "",
      sendLabel: "Senden",
      infoBox: {
        type: 0, // 0 - no, 1 - success, 2 - error
        content: ""
      },
      sitekey: process.env.VUE_APP_RECAPTCHA_SITE_KEY
    };
  },
  props: {
    data: Object,
    printClass: Function,
    className: String
  },
  methods: {
    onSubmit() {
      this.$refs.recaptcha.execute();
      this.sendLabel = "Sending...";
    },
    onVerify(recaptchaToken) {
      this.$refs.recaptcha.reset();
      var bodyFormData = new FormData();
      bodyFormData.set("name", this.name);
      bodyFormData.set("email", this.email);
      bodyFormData.set("message", this.message);
      bodyFormData.set("company", this.company);
      bodyFormData.set("recaptchaToken", recaptchaToken);

      const config = {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      };
      axios
        .post(
          "https://api.betterandmore.eu/bedrock/wp-json/better-api/v1/germandesk",
          bodyFormData,
          config
        )
        .then(response => {
          if (response.data == 0) {
            this.infoBox.type = 1;
            this.infoBox.content = "Email was send successfully.";
            this.sendLabel = "Send";
            this.name = "";
            this.company = "";
            this.message = "";
            this.email = "";
            setTimeout(() => {
              this.infoBox.type = 0;
            }, 3000);
          } else {
            this.infoBox.type = 2;
            this.infoBox.content = "Server side error";
            this.sendLabel = "Send";
            setTimeout(() => {
              this.infoBox.type = 0;
            }, 3000);
          }
        })
        .catch(err => {
          this.infoBox.type = 2;
          this.infoBox.content = "Cannot send email, please try again later.";
          this.sendLabel = "Send";
          setTimeout(() => {
            this.infoBox.type = 0;
          }, 3000);
          console.log(getErrorMessage(err));
          //helper to get a displayable message to the user
          function getErrorMessage(err) {
            let responseBody;
            responseBody = err.response;
            if (!responseBody) {
              responseBody = err;
            } else {
              responseBody = err.response.data || responseBody;
            }
            return responseBody.message || JSON.stringify(responseBody);
          }
        });
    },
    onExpired() {
      console.log("Expired");
    }
  }
};
</script>

<style scoped lang="scss">
@import "@/styles/framework/variables.sass";

.contact-form {
  background-color: $bg;
  padding: 2rem 1rem !important;
  &__title {
    color: $dark-blue;
    font-size: 1.7rem;
    font-family: "Gilroy bold";
    margin-bottom: 1rem;
  }
  &__info-text {
    font-size: 0.85rem;
    color: #959595;
    margin-bottom: 1.5rem;
    text-align: left;
  }
  &__inputs-box {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  &__paragraph-center {
    margin-bottom: 1rem;
    text-align: left;
    color: $dark-blue;
  }
  & .checkbox {
    margin-bottom: 1rem;
    & input[type="checkbox"] {
      margin-right: 0.5rem;
    }
  }
  &__term {
    color: $dark-blue;
  }
  &__send {
    background-color: $dark-blue;
    border-color: transparent;
    color: $white;
    &:hover {
      color: $white;
      border-color: transparent;
      background-color: lighten($dark-blue, 10%);
    }
    &:focus {
      background-color: $dark-blue;
      color: $white;
    }
  }
}
</style>
