import { render, staticRenderFns } from "./TwoTiles.vue?vue&type=template&id=0560e7aa&scoped=true&"
import script from "./TwoTiles.vue?vue&type=script&lang=js&"
export * from "./TwoTiles.vue?vue&type=script&lang=js&"
import style0 from "./TwoTiles.vue?vue&type=style&index=0&id=0560e7aa&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0560e7aa",
  null
  
)

export default component.exports